import { computed, observable } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { extractFromSlug } from 'helpers/utils';

class ExamStatusTableStore {
  constructor(examsStore, organizationStore, catalogStore) {
    this.examsStore = examsStore;
    this.organizationStore = organizationStore;
    this.catalogStore = catalogStore;
  }

  @observable examSKUTranslation = 'Exam SKU: ';

  @observable versionTranslation = ', Version: ';

  @observable userCourseTranslation = 'User/Exam ';

  @observable examStatusesTranslation = {
    requested: 'Requested',
    unscheduled: 'Unscheduled',
    scheduled: 'Scheduled',
    results_pending: 'Results pending',
    completed: 'Completed',
    retake_available: 'Retake available',
    retake_scheduled: 'Retake scheduled',
    retake_results_pending: 'Retake results pending',
    retake_complete: 'Retake complete',
  };

  // Transform raw data to fit datatable
  @computed get transformedData() {
    const result = {};
    const selectedUsers = this.organizationStore.selectedUsersByManagerList;
    if (selectedUsers.length === 0) {
      return [];
    }

    this.examsStore.reporteesExamStatus
      .filter((examObject) => selectedUsers.includes(examObject.username))
      .forEach((item) => {
        const user = item.username;
        const exam = item.exam_slug;
        const userObject = this.organizationStore.getUserByUsername(
          item.username,
        );
        if (!result[user]) {
          result[user] = {
            username: !isEmpty(trim(userObject.preferred_name))
              ? `${item.username} (${userObject.preferred_name})`
              : `${item.username}`,
          };
        }

        // Future Work: Show all status' of the same exam instead of just the most recent
        // Update exam status if more recent record found
        if (exam in result[user]) {
          const existingDate = result[user][exam].examDate;
          const newDate = item.retake_date
            ? new Date(item.retake_date)
            : new Date(item.exam_date);
          if (newDate > existingDate) {
            result[user][exam] = {
              status: item.status,
              examDate: newDate,
            };
          }
        } else {
          // add status for a new exam
          result[user][exam] = {
            status: item.status,
            examDate: item.retake_date
              ? new Date(item.retake_date)
              : new Date(item.exam_date),
          };
        }
      });

    return Object.values(result).sort((a, b) => {
      // Extracting the full name from the displayed username (Full Name) format
      // in order to sort by Full name even though the username is also appearing
      const fullNameA = a.username.replace(/.*\(|\).*/g, '');
      const fullnameB = b.username.replace(/.*\(|\).*/g, '');
      return fullNameA.localeCompare(fullnameB);
    });
  }

  // Create datatable columns dynamically
  @computed get tableColumns() {
    const examStatus = this.examsStore.reporteesExamStatus;
    const selectedUsers = this.organizationStore.selectedUsersByManagerList;
    const uniqueExams = [
      ...new Set(
        examStatus
          .filter((examObject) => selectedUsers.includes(examObject.username))
          // .map(item => item.exam_slug),
          .filter(
            (examSummary, index, self) =>
              self.findIndex(
                (exam) => exam.exam_slug === examSummary.exam_slug,
              ) === index,
          )
          .map((item) => {
            const { code, version } = extractFromSlug(item.exam_slug);
            let examTitle = `${this.examSKUTranslation} ${code}
             ${this.versionTranslation}
             ${version}`;

            const catalogEntries =
              this.catalogStore.groupedAllCatalogEntries[code];

            // If exam still exists in catalog, get its title
            if (
              this.organizationStore.hasCatalogData &&
              catalogEntries &&
              catalogEntries[version]
            ) {
              examTitle =
                this.catalogStore.groupedAllCatalogEntries[code][version].title;
            }

            const extendedUniqueObject = {
              exam_slug: item.exam_slug,
              username: item.username,
              title: examTitle,
            };

            return extendedUniqueObject;
          }),
      ),
    ];

    const examStatusFormatter = (cell) => {
      if (typeof cell !== 'undefined' && cell.status !== 'canceled') {
        let text = this.examStatusesTranslation[cell.status];
        if (
          [
            'scheduled',
            'completed',
            'retake_scheduled',
            'retake_complete',
          ].includes(cell.status)
        ) {
          text = `${text} ${cell.examDate.toLocaleDateString()}`;
        }
        return text;
      }
      return '';
    };

    // populate with data table field properties
    const columns = [
      {
        id: 'username',
        accessor: 'username',
        Header: this.userCourseTranslation,
        patternflyThProps: {
          className: 'custom-table-header-main',
        },
      },
    ].concat(
      uniqueExams.map((exam) => ({
        id: exam.exam_slug,
        accessor: (cellValue) => cellValue[exam.exam_slug],
        Header: () => {
          return this.organizationStore.addHover({
            tooltipText: exam.title,
            text: exam.exam_slug,
          });
        },
        Cell: (props) => {
          return examStatusFormatter(props?.cell?.value);
        },
        disableSortBy: true,
        patternflyThProps: {
          className: 'custom-table-header',
        },
      })),
    );

    return columns;
  }
}

export default ExamStatusTableStore;
