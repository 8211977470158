/**
 * @file Provides utility methods to support code reusability
 * @author Joshua Jack <jjack@redhat.com>
 */

import { debounce } from 'throttle-debounce';
import Papa from 'papaparse';
import moment from 'moment-timezone';
import 'moment/min/locales.min';

/**
 * Returns a date object based on a provided ISO-8601 string
 * @param {string} string - date in ISO-8601 format
 * @return {Date} a date object
 */
const parseStringUTC = (string) => {
  let s = string;

  s = s.split(/\D/);
  s[6] = s[6] ? `0.${s[6]}` * 1000 : 0;

  const args = Date.UTC(
    s[0],
    (s[1] -= 1),
    s[2],
    s[3] || 0,
    s[4] || 0,
    s[5] || 0,
    s[6] || 0,
  );

  return new Date(args);
};

/**
 * Returns an object based on a provided ISO-8601 string
 * @param {string} [query=window.location.search] - query string to parse
 * @return {Object} an object containing query string keys and values.
 */
const getQueryString = (query) => {
  const q = (query || window.location.search).substr(1).split('&');

  if (q === '') {
    return {};
  }

  const b = {};
  let i = 0;

  while (i < q.length) {
    const p = q[i].split('=', 2);
    if (p.length === 1) {
      b[p[0]] = '';
    } else {
      b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
    }
    i += 1;
  }
  return b;
};

/**
 * Returns the difference between two days
 * @param {string} start - date in ISO-8601 format
 * @param {string} end - date in ISO-8601 format
 * @return {integer} the difference in days
 */
const getDaysDiff = (start, end) => {
  const diff = ((parseStringUTC(end) - parseStringUTC(start)) / 8.64e7).toFixed(
    2,
  );

  return diff > 0 ? diff : 0;
};

/**
 * Wraps an event (like onKeyUp) in a debounce function to limit how often it fires
 * @param args - arguments provided to the 'throttle-debounce' module
 */
const debounceEvent = (...args) => {
  const debounced = debounce(...args);
  return (e) => debounced(e);
};

/**
 * Returns form data
 * @param {string} $form - jQuery form
 * @return {Object} an object containing form data
 */
const formData = ($form) => {
  // TODO: Look for an ES6 or lodash method to do this
  const o = {};

  $form.serializeArray().forEach((item) => {
    o[item.name] = item.value;
  });

  return o;
};

/**
 * Returns Cookie by name
 * @param {string} name - cookie name
 * @return {Object} an object containing cookie data
 */
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length !== 2) {
    return null;
  }

  return parts.pop().split(';').shift();
};

/**
 * Returns string with first letter in uppercase
 * @param {string} string
 */
const transformToSentenceCase = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Returns whether or not an element has a length property
 * @param {string|Array|Object} element
 * @returns {boolean}
 */
const isEmpty = (element) => {
  if (element === undefined || !element.length) {
    return true;
  }

  return false;
};

/**
 * Returns whether or not an object contains empty values
 * @param {Object} object
 * @returns {boolean}
 */
const containsMissingValues = (object) =>
  Object.values(object).some((x) => x === null || x === '');

/**
 * Sorts an array by given object property
 * @param {Array} array
 * @param {string} sortProp
 */
const sortBy = (array, sortProp) =>
  array.sort((item1, item2) => {
    if (item1[sortProp] < item2[sortProp]) return -1;
    if (item1[sortProp] > item2[sortProp]) return 1;

    return 0;
  });

/**
 * Returns array of objects with unique values of a passed property
 * @param array
 * @param property — the criteria of uniqueness
 * @returns {*[]}
 */
const uniqBy = (array, property) => {
  const cb = typeof property === 'function' ? property : (o) => o[property];

  return [
    ...array
      .reduce((map, item) => {
        const key = cb(item);

        if (!map.has(key)) map.set(key, item);

        return map;
      }, new Map())
      .values(),
  ];
};

/**
 * Returns an array without occurences of given element
 * @param array
 * @param item
 * @returns {*}
 */
const pull = (array, item) => array.filter((e) => e !== item);

/**
 * Returns a Promise that resolves to an array of parsed CSV values
 * @param file
 * @returns Promise
 */
async function asyncParseCSVFile(file) {
  return new Promise((resolve, reject) => {
    const completeCallback = async (result) => {
      if (!result) {
        reject();
      }

      if (result && result.errors.length) {
        reject(result.errors);
      }

      resolve(result && result.data);
    };

    const config = {
      header: true,
      skipEmptyLines: true,
      complete: completeCallback,
    };

    Papa.parse(file, config);
  });
}

/**
 * Returns a promise which resolves with zeroeth element of each row of CSV
 * @param file
 */
async function asyncParseCSVFileSingleLine(file) {
  return new Promise((resolve) => {
    const completeCallback = async (result) => {
      const data = result.data.map((row) => row[0]);
      resolve(data);
    };

    const config = {
      header: false,
      skipEmptyLines: true,
      complete: completeCallback,
    };

    Papa.parse(file, config);
  });
}

/**
 * Returns an array of files with a given format.
 */
function validateIncomingFiles(acceptedFiles, rejectedFiles, fileFormat) {
  const validFiles = [...acceptedFiles];
  rejectedFiles.forEach((file) => {
    if (file.name.endsWith(`.${fileFormat}`)) {
      validFiles.push(file);
    }
  });
  return validFiles;
}

const getCourseTitleByLanguage = (translations, language) => {
  if (!translations) {
    return '';
  }
  const getTranslation = () => {
    return typeof translations === 'object'
      ? Object.values(translations)
      : translations;
  };

  const title =
    getTranslation()?.find((tr) => tr.language === language)?.title ||
    getTranslation()?.find((tr) => tr.language === 'en-US')?.title;
  return title;
};

/**
 * Returns an string with the passed datetime formatted to a language (locale).
 * locale should be passed from i18n.language, using useTranslation()
 * format should come from `Localized Formats` list in https://momentjs.com/docs/#/parsing/special-formats/
 */
function formatDateTime(
  datetime,
  locale = 'en-US',
  format = 'ddd, MMM DD YYYY, hh:mm A z',
) {
  return moment(datetime).locale(locale).format(format);
}

function getTimeZoneInfo(
  date,
  locale,
  options = {
    day: '2-digit',
    timeZoneName: 'short',
  },
  sliceCount = 4,
) {
  return new Date(date).toLocaleDateString(locale, options).slice(sliceCount);
}

function getOfferingTitleByLanguage(offering, translations, language) {
  const title =
    translations?.find((tr) => tr.language === language)?.title ||
    offering.title;

  return title;
}

function getProperCaseForAString(str) {
  if (str === null || str === '') return false;

  return str
    .toString()
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    );
}

export {
  parseStringUTC,
  getQueryString,
  getDaysDiff,
  debounceEvent,
  formData,
  getCookie,
  transformToSentenceCase,
  isEmpty,
  containsMissingValues,
  sortBy,
  uniqBy,
  pull,
  asyncParseCSVFile,
  asyncParseCSVFileSingleLine,
  validateIncomingFiles,
  getCourseTitleByLanguage,
  formatDateTime,
  getTimeZoneInfo,
  getOfferingTitleByLanguage,
  getProperCaseForAString,
};
