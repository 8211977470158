import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { withErrorBoundary } from 'react-error-boundary';
import { Select, SelectOption } from '@patternfly/react-core/deprecated';
import {
  Flex,
  FlexItem,
  Modal,
  ModalVariant,
  Button,
  DatePicker,
  HelperText,
  HelperTextItem,
  Popover,
} from '@patternfly/react-core';
import CalendarIcon from '@patternfly/react-icons/dist/esm/icons/outlined-calendar-alt-icon';
import InfoCircleIcon from '@patternfly/react-icons/dist/esm/icons/info-circle-icon';

import { handleError } from 'services/ErrorService';
import GenericViewError from 'components/Error/GenericViewError';
import { storesContext } from 'stores';

import './styles.scss';
import { ManagementDashboardTab } from '../types';

export const getLocalizedCustomDateFormatFunc = (dateFormat) => (date) => {
  return moment(date).format(dateFormat);
};

export const getLocalizedCustomDateParseFunc = (dateFormat) => (str) => {
  return moment(str, dateFormat).toDate();
};

export const validatePreventFutureDate = (errorMessage) => (d) =>
  moment(d).isAfter(moment(), 'day') ? errorMessage : undefined;

export const validateLimit = (referenceDate, errorMessage) => (d) =>
  moment(d).isBefore(moment(referenceDate), 'day') ? errorMessage : undefined;

const dateFormat = moment.localeData().longDateFormat('L') || 'YYYY-MM-DD';
const localizedCustomDateFormatFunc =
  getLocalizedCustomDateFormatFunc(dateFormat);
const localizedCustomDateParseFunc =
  getLocalizedCustomDateParseFunc(dateFormat);

const OrgFormControl = (props) => {
  const { t, i18n } = useTranslation();
  const [isOrgSelectorOpen, setIsOrgSelectorOpen] = useState(false);
  const [isSubscriptionSelectorOpen, setIsSubscriptionSelectorOpen] =
    useState(false);
  const [isCustomDateModalOpen, setCustomDateModalOpen] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const { organizationStore, userStore, vocabularyStore } =
    useContext(storesContext);

  const filteredOrgs = organizationStore.organizations.filter((org) => {
    const admins = org.admin_set.map((user) => {
      return user.username;
    });
    const managers = org.manager_set.map((user) => {
      return user.username;
    });
    return [...admins, ...managers].includes(userStore.user?.username);
  });

  const handleModalToggle = () => {
    setCustomDateModalOpen((prev) => !prev);
  };

  const handleApplyCustomDate = () => {
    if (fromDate && toDate) {
      organizationStore.switchUsageSummary(
        i18n,
        vocabularyStore,
        'customDateRange',
        {
          from: fromDate,
          to: toDate,
        },
      );
      handleModalToggle();
    } else {
      console.error('INVALID DATE RANGE');
    }
  };

  const onFromChange = (_e, value, date) => {
    if (moment(value, dateFormat, true).isValid()) {
      setFromDate(date);

      if (moment(date).isAfter(moment(toDate), 'day')) {
        setToDate(null);
      }
    }
  };

  const onToChange = (_e, value, date) => {
    if (moment(value, dateFormat, true).isValid()) {
      setToDate(date);

      if (moment(date).isBefore(moment(fromDate), 'day')) {
        setToDate(null);
      }
    }
  };

  if (
    organizationStore.isLoadingOrganizations ||
    organizationStore.didFailToFetchOrganizations
  ) {
    return null;
  }

  const customRangeLabel =
    organizationStore.summaryType === 'customDateRange'
      ? `${moment(organizationStore.customDateFromDate).format(
          dateFormat,
        )} - ${moment(organizationStore.customDateToDate).format(dateFormat)}`
      : t('Custom date range');

  return (
    <>
      <Flex
        alignItems={{ default: 'alignItemsCenter' }}
        justifyContent={{ default: 'justifyContentCenter' }}
        className="organization-form"
      >
        <FlexItem>
          <Select
            data-analytics-id="org-dropdown-mngmt-dashboard-lp"
            className="organization-form__select"
            isOpen={isOrgSelectorOpen}
            isDisabled={filteredOrgs?.length < 1}
            onToggle={() => setIsOrgSelectorOpen((prev) => !prev)}
            selected={organizationStore.organization?.id}
            selections={organizationStore.organization.name}
            onSelect={(event, selection) => {
              organizationStore.setOrganization(selection);
              setIsOrgSelectorOpen(false);

              if (
                organizationStore.isAdminOfOrg(
                  organizationStore.findOrganizationById(selection),
                )
              ) {
                props.setActiveTab(ManagementDashboardTab.Summary);
              } else {
                props.setActiveTab(ManagementDashboardTab.Team);
              }
            }}
          >
            {filteredOrgs.map((org) => {
              return (
                <SelectOption key={org.doc_id} value={org.doc_id}>
                  {org.name}
                </SelectOption>
              );
            })}
          </Select>
        </FlexItem>

        {props.showSubscriptionTypeFilter && (
          <FlexItem>
            <Select
              data-analytics-id="subscription-dropdown-mngmt-dashboard-lp"
              className="organization-form__select"
              isOpen={isSubscriptionSelectorOpen}
              isDisabled={filteredOrgs?.length < 1}
              onToggle={() => setIsSubscriptionSelectorOpen((prev) => !prev)}
              selections={organizationStore.summaryType}
              onSelect={(event, selection) => {
                if (selection === 'customDateRange') {
                  setCustomDateModalOpen(true);
                } else {
                  organizationStore.switchUsageSummary(
                    i18n,
                    vocabularyStore,
                    selection,
                  );
                  setIsSubscriptionSelectorOpen(false);
                }
              }}
            >
              <SelectOption
                key="all"
                value="all"
                data-analytics-id="all-option-subscription-dropdown-mngmt-dashboard-lp"
              >
                {t('All subscriptions')}
              </SelectOption>
              <SelectOption
                key="current"
                value="current"
                data-analytics-id="current-option-subscription-dropdown-mngmt-dashboard-lp"
              >
                {t('Current subscriptions')}
              </SelectOption>
              <SelectOption
                key="customDateRange"
                value="customDateRange"
                data-analytics-id="customDateRange-option-subscription-dropdown-mngmt-dashboard-lp"
              >
                {customRangeLabel}
              </SelectOption>
            </Select>
          </FlexItem>
        )}

        <Popover
          aria-label={t('Data selection guide')}
          headerContent={t('Data selection guide')}
          bodyContent={
            <div>
              {t(
                "Display subscription data by selecting 'All Subscriptions' to view complete historical and current activity, 'Current Subscriptions' to focus on active engagements, or define a specific date range to customize your report.",
              )}
            </div>
          }
        >
          <Button
            variant="plain"
            icon={
              <InfoCircleIcon color="var(--pf-v5-global--info-color--100)" />
            }
          />
        </Popover>
      </Flex>
      <Modal
        hasNoBodyWrapper
        aria-label={t('Select date range')}
        position="top"
        positionOffset="230px"
        variant={ModalVariant.small}
        title={t('Select date range')}
        titleIconVariant={CalendarIcon}
        isOpen={isCustomDateModalOpen}
        onClose={handleModalToggle}
        className="organization-form__custom-date-modal"
        actions={[
          <Button
            key="apply"
            variant="primary"
            onClick={handleApplyCustomDate}
            isDisabled={!(toDate && fromDate)}
          >
            {t('Apply')}
          </Button>,
          <Button key="cancel" variant="link" onClick={handleModalToggle}>
            {t('Cancel')}
          </Button>,
        ]}
      >
        <div className="organization-form__custom-date-modal-body">
          <DatePicker
            value={fromDate ? moment(fromDate).format(dateFormat) : ''}
            onChange={onFromChange}
            placeholder={dateFormat}
            dateFormat={localizedCustomDateFormatFunc}
            dateParse={localizedCustomDateParseFunc}
            invalidFormatText={t('Invalid date')}
            validators={[
              validatePreventFutureDate(t('Future dates not allowed.')),
            ]}
            helperText={
              <HelperText>
                <HelperTextItem>{t('Select start date.')}</HelperTextItem>
              </HelperText>
            }
          />
          <span>{t('to')}</span>
          <DatePicker
            value={toDate ? moment(toDate).format(dateFormat) : ''}
            onChange={onToChange}
            placeholder={dateFormat}
            dateFormat={localizedCustomDateFormatFunc}
            dateParse={localizedCustomDateParseFunc}
            invalidFormatText={t('Invalid date')}
            validators={[
              validatePreventFutureDate(t('Future dates not allowed.')),
              validateLimit(
                fromDate,
                t('End date cannot be before start date.'),
              ),
            ]}
            helperText={
              <HelperText>
                <HelperTextItem>{t('Select end date.')}</HelperTextItem>
              </HelperText>
            }
            popoverProps={{
              zIndex: 9999,
            }}
          />
        </div>
      </Modal>
    </>
  );
};

OrgFormControl.propTypes = {
  showSubscriptionTypeFilter: PropTypes.bool,
  setActiveTab: PropTypes.func.isRequired,
};

OrgFormControl.defaultProps = {
  showSubscriptionTypeFilter: false,
};

export default withErrorBoundary(
  observer(OrgFormControl),
  GenericViewError,
  handleError,
);
