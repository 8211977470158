import { computed, action, observable } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { extractFromSlug } from 'helpers/utils';
import { getReporteesLabHours } from '../../../../../services/LabHoursService';

class LabUsageTableStore {
  constructor(organizationStore, catalogStore) {
    this.organizationStore = organizationStore;
    this.catalogStore = catalogStore;
  }

  @observable courseSKUTranslation = 'Course SKU: ';

  @observable versionTranslation = ', Version: ';

  @observable userLabTranslation = 'User/Lab ';

  @observable reporteesLabUsage = [];

  @action async getReporteesLabUsage(users) {
    try {
      const labUsageData = await getReporteesLabHours(users);
      this.reporteesLabUsage = labUsageData;
    } catch (err) {
      throw err;
    }
  }

  // Transform raw data to fit datatable
  @computed get transformedData() {
    const result = {};
    const selectedUsers = this.organizationStore.selectedUsersByManagerList;
    if (selectedUsers.length === 0) {
      return [];
    }

    this.reporteesLabUsage
      .filter((labSummaryObject) =>
        selectedUsers.includes(labSummaryObject.username),
      )
      .forEach((item) => {
        if (!result[item.username]) {
          const userObject = this.organizationStore.getUserByUsername(
            item.username,
          );
          result[item.username] = {
            // eslint-disable-next-line camelcase
            username: !isEmpty(trim(userObject.preferred_name))
              ? `${item.username} (${userObject.preferred_name})`
              : `${item.username}`,
          };
        }

        // Revisit this logic: currently assuming 0 means <1
        result[item.username][item.offering_slug] =
          item.hoursUsed > 0 ? `${item.hoursUsed}` : `<1`;
      });

    return Object.values(result);
  }

  // Create datatable columns based off selected users
  @computed get tableColumns() {
    const labUsage = this.reporteesLabUsage;
    const selectedUsers = this.organizationStore.selectedUsersByManagerList;

    const uniqueLabs = [
      ...new Set(
        labUsage
          .filter((labSummaryObject) =>
            selectedUsers.includes(labSummaryObject.username),
          )
          .filter(
            (labSummary, index, self) =>
              self.findIndex(
                (lab) => lab.offering_slug === labSummary.offering_slug,
              ) === index,
          )
          .map((item) => {
            const { code, version } = extractFromSlug(item.exam_slug);
            let courseTitle = `${this.courseSKUTranslation} ${code} ${this.versionTranslation} ${version}`;

            const catalogEntries =
              this.catalogStore.groupedAllCatalogEntries[code];

            // If course still exists in catalog, get its title
            if (
              this.organizationStore.hasCatalogData &&
              catalogEntries &&
              catalogEntries[version]?.translations
            ) {
              this.catalogStore.groupedAllCatalogEntries[code][
                version
              ].translations.forEach((tr) => {
                if (tr.language === 'en-US') {
                  courseTitle = tr.title;
                }
              });
            }

            const extendedUniqueObject = {
              offering_slug: item.offering_slug,
              username: item.username,
              title: courseTitle,
            };

            return extendedUniqueObject;
          }),
      ),
    ];

    // populate with data table field properties
    const columns = [
      {
        id: 'username',
        accessor: 'username',
        Header: this.userLabTranslation,
        sort: true,
        patternflyThProps: {
          className: 'custom-table-header-main',
        },
      },
    ].concat(
      uniqueLabs.map((lab) => ({
        id: lab.offering_slug,
        accessor: (cellValue) => cellValue[lab.offering_slug],
        Header: () => {
          return this.organizationStore.addHover({
            tooltipText: lab.title,
            text: lab.offering_slug,
          });
        },
        disableSortBy: true,
        patternflyThProps: {
          className: 'custom-table-header',
        },
      })),
    );

    return columns;
  }
}

export default LabUsageTableStore;
