import { observable, action, computed } from 'mobx';

class SessionDetailsStore {
  @observable opened = true;

  @observable courseTitle = '';

  @observable sessionSlug = '';

  @observable sessionContent = {};

  @observable sessionModality = {};

  constructor(data) {
    this.sessionSlug = data.premvt_session_slug;
    this.sessionModality = data?.modality || 'course';
    this.sessionContent = data.sessions?.find((s) =>
      this.sessionSlug?.endsWith(s.slug),
    );
  }

  @action toggle = () => {
    this.opened = !this.opened;
  };

  @computed get headerTitleScheduleSession() {
    // Format: "Red Hat Application Development I: Programming in Java EE - Session 6"
    const sessionNumber = parseInt(
      this.sessionSlug?.split('-')[2]?.replace('s', ''),
      10,
    );
    return `${this.courseTitle && `${this.courseTitle} - `}Session ${
      !Number.isNaN(sessionNumber) ? sessionNumber : ''
    }`;
  }
}

export default SessionDetailsStore;
