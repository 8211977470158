import { extractFromSlug } from 'helpers/utils';
import { action, observable } from 'mobx';
import moment from 'moment';

class ClassesWidgetStore {
  @observable opened = true;

  @observable visibleItems = 5;

  @observable openedPremiumClassesInProgress = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action showMoreItems() {
    this.visibleItems += 5;
  }

  @action toggle = () => {
    this.opened = !this.opened;
  };

  @action togglePremiumClassesInProgress = () => {
    this.openedPremiumClassesInProgress = !this.openedPremiumClassesInProgress;
  };

  // Sorts the scheduled sessions the user is enrolled in
  // Returns an object with the enrollment, the scheduledSession and
  // the PremiumClass the scheduledSession is part of, sorted by start_time
  // eslint-disable-next-line class-methods-use-this
  @action getSortedEnrollmentsFromPremiumClasses(premiumClassesList) {
    const enrollments = [];
    premiumClassesList.forEach((premClass) => {
      enrollments.push(...premClass.enrollments);
    });

    const sessionsToSort = [];
    enrollments.forEach((enrollment) => {
      premiumClassesList.forEach((premiumClass) => {
        const foundSession = premiumClass.scheduledSessions.find((session) => {
          const now = moment();
          return (
            session.doc_id === enrollment.premvt_session_uuid &&
            now.isBefore(session.end_time)
          );
        });
        if (foundSession) {
          sessionsToSort.push({
            premiumClass,
            scheduledSession: foundSession,
            enrollment,
          });
        }
      });
    });

    const sortedSessions = sessionsToSort.sort(
      (a, b) =>
        moment(a.scheduledSession.start_time) -
        moment(b.scheduledSession.start_time),
    );

    return sortedSessions;
  }

  // eslint-disable-next-line class-methods-use-this
  @action prepareSessionDataForRenderingCard(premSession) {
    const courseOfferingSlug = premSession.premiumClass.offering_slug;
    const { code: courseCode } = extractFromSlug(courseOfferingSlug);
    const sessionSlug = premSession.scheduledSession.premvt_session_slug;
    const sessionContent = premSession.premiumClass.sessions.find((s) =>
      sessionSlug.endsWith(s.slug),
    );
    const title =
      this.rootStore.catalogStore.uniqueCatalogEntries[courseCode]?.title || '';
    const course = {
      name: title,
      code: courseCode.toUpperCase(),
      offering_slug: courseOfferingSlug,
    };

    return { course, sessionContent, sessionSlug };
  }
}

export default ClassesWidgetStore;
