import React, { useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import moment from 'moment-timezone';

import { SimpleSelect } from 'react-selectize';

import {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Row,
} from 'react-bootstrap';
import { Alert, Popover, Switch } from '@patternfly/react-core';
import { InfoCircleIcon } from '@patternfly/react-icons';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import { Trans, useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import Avatar from 'react-avatar';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import './styles.scss';
// import plusSquare from 'img/icons/plus-square.svg';
import minusSquare from 'img/icons/minus-square.svg';
import TIMEZONE_LOCALES from 'config/timezones';
import AchivementList from 'components/UserAchievements/AchievementList';
import { SSO_PROFILE_EDIT_PAGE } from 'config/constants';
import { useFeature } from '@ole-ui/ole-ui-components';
import useMobxStores from 'hooks/useMobxStores';
import FormUIStore from './store';

const ProfileForm = () => {
  const { userStore, vocabularyStore, uiStore, pendoStore, configStore } =
    useMobxStores();

  const store = useLocalStore(
    () => new FormUIStore(vocabularyStore, userStore, uiStore),
  );
  const isProfileReadOnly = useFeature({
    section: 'profile',
    flagName: 'read_only',
    defaultFlagValue: true,
  });

  const isCourseHUDSwitchVisible = useFeature({
    section: 'course',
    flagName: 'hud_switch_visible',
    defaultFlagValue: false,
  });

  const isHUDEnabledByUser =
    userStore.userPreferences?.active_course_view === 'hud';

  const isProfileEditable = !isProfileReadOnly || userStore.isLocalLogin;

  const { t } = useTranslation();

  const timezone =
    userStore.user.default_timezone || moment.tz.guess() || undefined;

  const handleFieldChange = (fieldName) => {
    return (event) => {
      userStore.user[fieldName] = event.target.value;
    };
  };

  const handleBlur = (fieldName) => {
    return () => {
      const update = {};
      update[fieldName] = userStore.user[fieldName];
      userStore.partiallyUpdateUserRecord(update);
      store.toggle(fieldName);
    };
  };

  const handleTimezoneChange = (newTimezone) => {
    userStore.user.default_timezone = newTimezone;
    const update = { default_timezone: newTimezone };
    userStore.partiallyUpdateUserRecord(update);
    store.toggle('default_timezone');
  };

  const handleCountryChange = (newCountry) => {
    if (!newCountry?.value) return;
    userStore.user.default_country = newCountry.value.toUpperCase();
    const update = { default_country: newCountry.value.toUpperCase() };
    userStore.partiallyUpdateUserRecord(update);
    store.toggle('default_country');
  };

  const removeDefaultEmail = () => {
    userStore.user.default_email = '';
    const update = { default_email: '' };
    userStore.partiallyUpdateUserRecord(update);
  };

  const handleActiveViewChange = (_event, checked) => {
    userStore.updateUserPreferences(
      {
        active_course_view: checked ? 'hud' : 'legacy',
      },
      true,
    );

    if (!checked) {
      const guideId = configStore.pendoGuides?.hudDisabledFeedbackPrompt;

      if (guideId) {
        pendoStore.showGuideById(guideId);
      }
    }
  };

  const handleReminderOptoutChange = (_event, checked) => {
    userStore.updateUserPreferences(
      {
        premvt_reminder_optout: checked,
      },
      true,
    );
  };


  useEffect(() => {
    userStore.user = Object.assign({}, userStore.user);
  }, []);

  return (
    <>
      {!isProfileEditable && (
        <div>
          <Alert
            className="profile-form-alert"
            variant="info"
            title={t('Profile update instructions')}
          >
            <p>
              <Trans>
                To update your name or email, please visit your{' '}
                <a
                  href={SSO_PROFILE_EDIT_PAGE}
                  target="_blank"
                  rel="noreferrer"
                >
                  Red Hat profile page
                </a>
                . Changes you make there will be reflected here upon your next
                login.
              </Trans>
            </p>
            <p>
              <Trans>
                <strong>Important</strong>: After you&apos;ve updated your
                information on the Red Hat profile page, please log out of RHLS
                and log back in to ensure your profile is updated with the new
                information.
              </Trans>
            </p>
          </Alert>
        </div>
      )}
      <form className="profile-form">
        <Row className="profile-header">
          <div className="profile-header-avatar">
            <Avatar
              size="150"
              round
              name={userStore.preferredName}
              src={userStore.avatar}
            />
          </div>
          <div className="profile-header-name">
            <h2>{userStore.user.preferred_name}</h2>
          </div>
        </Row>

        <Row>
          <FormGroup controlId="preferred_name">
            <Col sm={12} md={6}>
              <ControlLabel>
                {t('Name')}{' '}
                {store.visibleList.includes('preferred_name') && (
                  <span className="updated"> {t('updated')}</span>
                )}
              </ControlLabel>
            </Col>
            <Col sm={12} md={6}>
              <FormControl
                type="text"
                defaultValue={userStore.user.preferred_name}
                onChange={handleFieldChange('preferred_name')}
                onBlur={handleBlur('preferred_name')}
                disabled={!isProfileEditable}
              />
            </Col>
          </FormGroup>
        </Row>

        <Row>
          <FormGroup controlId="default_email">
            <Col sm={12} md={6}>
              <ControlLabel>
                {t('Email address')}{' '}
                {store.visibleList.includes('default_email') && (
                  <span className="updated"> {t('updated')}</span>
                )}
              </ControlLabel>
            </Col>
            <Col sm={12} md={6}>
              <Col sm={10} className="input-with-btn">
                <FormControl
                  type="text"
                  // defaultValue={userStore.user.default_email}
                  onChange={handleFieldChange('default_email')}
                  onBlur={handleBlur('default_email')}
                  value={
                    userStore.user.default_email
                      ? userStore.user.default_email
                      : ''
                  }
                  disabled={!isProfileEditable}
                />
              </Col>
              <Col sm={1}>
                {userStore.user.default_email && (
                  <Button
                    type="button"
                    className="cc-email-action-btn"
                    onClick={removeDefaultEmail}
                    title={t('Remove this email address')}
                    disabled={!isProfileEditable}
                  >
                    <SVG src={minusSquare}>
                      {t('Remove this email address')}
                    </SVG>
                  </Button>
                )}
              </Col>
            </Col>
          </FormGroup>
        </Row>

        <Row className="disabled-info">
          <Col sm={12} md={6}>
            <ControlLabel>{t('Username')}</ControlLabel>
          </Col>
          <Col sm={12} md={6}>
            <span>
              {userStore.user?.external_username || userStore.username}
            </span>
          </Col>
        </Row>

        {userStore.currentSubscription?.groups?.length && (
          <Row className="disabled-info">
            <Col sm={12} md={6}>
              <ControlLabel>{t('Company')}</ControlLabel>
            </Col>
            <Col sm={12} md={6}>
              <span>{userStore.currentSubscription.groups[0]}</span>
            </Col>
          </Row>
        )}

        <Row>
          <FormGroup controlId="default_timezone">
            <Col sm={12} md={6}>
              <ControlLabel>
                {t('Time zone')}{' '}
                {store.visibleList.includes('default_timezone') && (
                  <span className="updated"> {t('updated')}</span>
                )}
              </ControlLabel>
            </Col>
            <Col sm={12} md={6}>
              <TimezonePicker
                defaultValue={timezone}
                placeholder={t('Select timezone')}
                onChange={handleTimezoneChange}
                timezones={TIMEZONE_LOCALES}
              />
            </Col>
          </FormGroup>
        </Row>

        <Row>
          <FormGroup controlId="default_country">
            <Col sm={12} md={6}>
              <ControlLabel>
                {t('Country')}
                {store.visibleList.includes('default_country') && (
                  <span className="updated"> {t('updated')}</span>
                )}
              </ControlLabel>
            </Col>
            <Col sm={12} md={6} className="select-default-country">
              <SimpleSelect
                id="profile-country-select"
                options={store.countries}
                value={store.selectedUserCountry}
                placeholder={t('Select A Country')}
                onValueChange={handleCountryChange}
                dropdownDirection="-1"
              />
            </Col>
          </FormGroup>
        </Row>

        {userStore.isPremiumSubscriber && (
          <Row>
            <Col sm={12} md={6}>
              <ControlLabel>
                {t('Opt-out of RHLS Premium session reminder emails.')}
              </ControlLabel>
              <Popover
                aria-label={t(
                  'Opt-out of RHLS Premium session reminder emails',
                )}
                headerContent={t('Premium session reminder email opt-out')}
                bodyContent={t(
                  'To stop receiving reminder emails about your premium session enrollments, toggle this switch on.',
                )}
              >
                <Button className="optout-premvt-info-button">
                  <InfoCircleIcon
                    className="optout-premvt-info-icon"
                    color="var(--pf-global--info-color--100)"
                  />
                </Button>
              </Popover>
            </Col>
            <Col sm={12} md={6} className="switch-optout-prem-reminder">
              <Switch
                id="profile-optout-prem-reminder-switch"
                aria-label={t(
                  'Opt-out of RHLS Premium session reminder emails',
                )}
                isChecked={Boolean(userStore.user.preferences?.premvt_reminder_optout)}
                onChange={handleReminderOptoutChange}
              />
            </Col>
          </Row>
        )}

        <Row>
          <FormGroup controlId="default_country">
            <Col sm={12} md={6}>
              <ControlLabel>{t('Achievements')}</ControlLabel>
            </Col>
            <Col sm={12} md={6} className="select-default-country">
              <AchivementList />
            </Col>
          </FormGroup>
        </Row>

        {isCourseHUDSwitchVisible && (
          <Row>
            <FormGroup controlId="application_preferences">
              <Col sm={12} md={6}>
                <ControlLabel>{t('Preferences')}</ControlLabel>
              </Col>
              <Col sm={12} md={6} className="select-default-country">
                <div>
                  <Switch
                    id="course-hud-switch"
                    label={t('Enable HUD on course page')}
                    isChecked={isHUDEnabledByUser}
                    onChange={handleActiveViewChange}
                    ouiaId="course-hud-switch"
                  />
                </div>
              </Col>
            </FormGroup>
          </Row>
        )}
      </form>
    </>
  );
};

export default observer(ProfileForm);
