import React, { useEffect, useMemo, useState } from 'react';
import { OfferingInfoBox, OfferingStats } from '@ole-ui/ole-ui-components';
import { Label, LabelGroup } from '@patternfly/react-core';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'react-error-boundary';
import ComponentError from 'components/Error/ComponentError';
import { handleError } from 'services/ErrorService';
import LessonIndicators from 'components/Catalog/Card/Lesson/LessonIndicators';
import TableOfContentsV2 from 'components/Course/TableOfContentsV2';
import {
  CatalogEntry,
  CourseMetadata as CourseMetadataType,
  VocabularyItem,
  VocabularyNamespaces,
} from 'types';
import { CourseProgressStructureItem, CourseStructureItem } from 'types/course';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import useMobxStores from 'hooks/useMobxStores';

import RelatedContent from './RelatedContent';

import './styles.scss';

type Props = {
  structure?: CourseStructureItem[];
  progressStructure?: CourseProgressStructureItem[];
  courseCatalogEntry?: CatalogEntry;
  metadata?: CourseMetadataType;
  currentLanguage?: string;
  asCollateral?: boolean;
};

const extractTranslationLanguage = (
  translations: string[],
  currentLanguage: string,
) =>
  translations?.find((translation) => translation === currentLanguage) ||
  translations?.find(
    (translation) => translation === 'en-US' || translation === 'en',
  ) ||
  translations?.[0] ||
  'en-US';

const LessonOverview = ({
  courseCatalogEntry,
  structure,
  progressStructure,
  metadata,
  currentLanguage = 'en-US',
  asCollateral = false,
}: Props) => {
  const { t } = useTranslation();
  const { vocabularyStore } = useMobxStores();
  const [productVocabulary, setProductVocabulary] = useState<VocabularyItem[]>(
    [],
  );

  const metadataLanguage = useMemo(
    () => extractTranslationLanguage(metadata?.translations, currentLanguage),
    [metadata, currentLanguage],
  );

  useEffect(() => {
    // loads vocabulary for product whenever metadata language changes
    const fetchVocabulary = async () => {
      try {
        const voc = await vocabularyStore.getVocabularyByNamespace(
          VocabularyNamespaces.OfferingProducts,
          metadataLanguage,
        );

        if (Array.isArray(voc)) {
          setProductVocabulary(voc);
        }
      } catch (e) {
        console.error('Error fetching product vocabulary for metadata', e);
      }
    };
    fetchVocabulary();
  }, [metadataLanguage]);

  let entryTags; // this tags are colorful tags like labels.

  if (courseCatalogEntry?.categories) {
    entryTags = (
      <LabelGroup numLabels={10}>
        {courseCatalogEntry?.categories?.map((category) => (
          <Label color="blue">{category}</Label>
        ))}
      </LabelGroup>
    );
  }

  const headingClasses = classNames('course-overview__h3', {
    'course-overview__h3--dark': asCollateral,
  });

  let productLabelList = [metadata?.product]; // initialize proeut label list with the singular metadata product

  // override the product label list with the vocabulary product labels if available:
  if (
    Array.isArray(productVocabulary) &&
    productVocabulary.length > 0 &&
    Array.isArray(metadata?.products)
  ) {
    const productVocabularyMap = productVocabulary.reduce((acc, item) => {
      acc[item.token] = item;
      return acc;
    }, {});

    productLabelList = metadata.products.map(
      (p) => productVocabularyMap[p]?.display_name,
    );
  }

  const IndicatorWrapperElem = asCollateral ? 'div' : OfferingInfoBox;
  const specialIndicators = {
    // audience: metadata?.audience?.[metadataLanguage]?.join?.(', '),
    product:
      productLabelList.length > 0 ? productLabelList.join?.(', ') : undefined,
  };

  const extraIndicatorsProps = asCollateral ? {} : specialIndicators;

  return (
    <div className="course-overview">
      {metadata && (
        <IndicatorWrapperElem tags={entryTags}>
          <LessonIndicators
            includeLabels
            disableLegend
            key="lesson-indicator"
            estimatedTime={metadata.total_duration}
            GECount={metadata.total_ge}
            quizCount={metadata.total_quiz}
            labCount={metadata.total_labs}
            level={metadata.level}
            videoTime={metadata.total_video_duration}
            durationUnit={metadata.duration_unit}
            {...extraIndicatorsProps}
            // competencies={metadata.skills?.join?.(', ')} // TODO: After MVP only
            // associatedCertifications={metadata.certifications} // TODO: After MVP only
          />
        </IndicatorWrapperElem>
      )}

      {structure &&
      structure.length > 1 &&
      progressStructure &&
      progressStructure.length > 0 ? (
        <>
          <h3 className={headingClasses}>{t('Table of Contents')}</h3>
          <div className="course-overview__toc">
            <TableOfContentsV2
              structure={structure}
              progressStructure={progressStructure}
            />
          </div>
        </>
      ) : null}

      {metadata && metadataLanguage && (
        <>
          <h3 className={headingClasses}>{t('Description')}</h3>
          {metadata.description?.[metadataLanguage] || ''}

          <h3 className={headingClasses}>{t('Objectives')}</h3>
          <ul className="course-overview__list">
            {metadata.objectives[metadataLanguage]?.map?.((objective) => (
              <li key={objective}>{objective}</li>
            ))}
          </ul>

          <h3 className={headingClasses}>{t('Prerequisites')}</h3>
          <ul className="course-overview__list">
            {metadata.prerequisites[metadataLanguage]?.map?.((prerequisite) => (
              <li key={prerequisite}>{prerequisite}</li>
            ))}
          </ul>

          <h3 className={headingClasses}>{t('Content match')}</h3>
          <RelatedContent relatedContent={metadata.related_contents} />

          {asCollateral && (
            <>
              <h3 className={headingClasses}>{t('Tags')}</h3>
              <div className="course-overview__collateral-tags">
                {!isNil(specialIndicators?.product) && (
                  <OfferingStats.Item label={t('Product:')}>
                    {specialIndicators?.product}
                  </OfferingStats.Item>
                )}
                {/* {!isNil(specialIndicators.audience) && (
                  <OfferingStats.Item label={t('Audience:')}>
                    {specialIndicators.audience}
                  </OfferingStats.Item>
                )} */}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default withErrorBoundary(LessonOverview, ComponentError, handleError);
