import React, { useContext, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useLocalStore, observer } from 'mobx-react';
import { extractFromSlug } from 'helpers/utils';
import { storesContext } from 'stores';
import CatalogCard from 'components/Catalog/Card';
import VersionCardStore from './store';

const VersionCard = (props) => {
  const { catalogStore, progressStore } = useContext(storesContext);
  const { catalogEntries, courseSlug, courseCode } = props;
  const store = useLocalStore(
    () => new VersionCardStore(catalogStore, progressStore),
  );
  const changeVersion = (newVersion) => {
    store.version = newVersion;
  };
  const versions = Object.keys(catalogEntries);

  let offering = catalogEntries[store.version];
  if (!offering?.code && offering?.videoClassroom) {
    offering = offering.videoClassroom;
  }

  useEffect(() => {
    const { version } = extractFromSlug(courseSlug);
    store.courseCode = courseCode;
    store.version = version;
  }, []);

  return offering ? (
    <CatalogCard
      displayVersionDropdown
      versions={versions}
      course={offering}
      progress={Math.round(store.progress * 100)}
      key={offering.course_code}
      onVersionChange={changeVersion}
    />
  ) : null;
};

VersionCard.propTypes = {
  catalogEntries: PropTypes.object,
  courseSlug: PropTypes.string,
  courseCode: PropTypes.string,
};

VersionCard.defaultProps = {
  catalogEntries: {},
  courseSlug: '',
  courseCode: '',
};

export default observer(VersionCard);
