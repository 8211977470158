import React, { useState, useMemo, useContext } from 'react';
import moment from 'moment';
import {
  Button,
  Label,
  Modal,
  ModalVariant,
  Alert,
  AlertGroup,
  AlertActionCloseButton,
  AlertVariant,
  Tooltip,
} from '@patternfly/react-core';
import { withErrorBoundary } from 'react-error-boundary';
import { handleError } from 'services/ErrorService';
import ComponentError from 'components/Error/ComponentError';
import { reportError } from 'config/sentry';
import { Link } from 'react-mobx-router5';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PremiumCalendarCard } from '@ole-ui/ole-ui-components';
import TimeIcon from '@patternfly/react-icons/dist/esm/icons/clock-icon';
import { toJS } from 'mobx';
import { storesContext } from 'stores';
import { extractFromSlug } from 'helpers/utils';
import { CONFIRMATION_DIALOGS } from '../constants';

const cardDurationTimeFormat = 'HH:mm';
const cardTimeFormat = 'LT';
const cardDateAndTimeFormat = 'LLL';

const getDuration = (secs) =>
  moment.utc(secs * 1000).format(cardDurationTimeFormat);

const getSessionNumber = (session) =>
  session?.premvt_session_slug?.split('-').slice(-1)[0]?.replace('s', '');

const getInstructorName = (session) => {
  const instructors = session?.instructors || [];
  if (instructors.length > 0) {
    const mainInstructor = instructors?.[0];
    return mainInstructor?.name;
  }
  return '';
};

const formatChapterTag = (t, chapter) => {
  if (chapter.type === 'chapter') {
    return t(`Chapter {{chapter}}`, {
      chapter: Number(chapter.tag?.replace('ch', '')),
    });
  }
  return chapter.tag;
};

const getChaptersFromCollateral = (t, collateral) => {
  if (!collateral) {
    return [];
  }

  return collateral.chapter_infos?.map((chapter) => ({
    ...chapter,
    formattedTag: formatChapterTag(t, chapter),
  }));
};

function Card({
  session,
  isLoadingCollaterals,
  onSchedule,
  onCancel,
  onReschedule,
  currentSubscriptionEndTime,
}) {
  const { t } = useTranslation();
  const [dialog, setDialog] = useState('');
  const [isPerformingAction, setIsPerformingAction] = useState(false);
  const [alerts, setAlerts] = useState([]); // idea: could be moved to global alerts store in a new attribute like "toastAlerts"
  const { userStore, progressStore } = useContext(storesContext);

  const title =
    session?.collateral?.offering_title || session?.relatedEntry?.title;

  const availableAction = session?.enrollmentInfo?.availableAction;

  const chapters = getChaptersFromCollateral(t, session?.collateral);

  const isRetired = !session?.relatedEntry;
  const startTime = useMemo(
    () => moment(session?.start_time),
    [session?.start_time],
  );
  const endTime = useMemo(() => moment(session?.end_time), [session?.end_time]);
  const duration = useMemo(
    () => getDuration(session?.duration_secs),
    [session?.duration_secs],
  );

  const isAfterCurrentSubscriptionEnd = useMemo(() => {
    return moment(currentSubscriptionEndTime).isBefore(endTime);
  }, [session?.end_time, currentSubscriptionEndTime]);

  const hasSessionAlreadyBegun = startTime.isBefore();
  const isSessionOver = endTime.isBefore();

  const removeAlert = (alertKey) => {
    setAlerts((oldList) => oldList.filter((a) => a.key !== alertKey));
  };

  const addAlert = (alert) => {
    setAlerts((oldList) => [...oldList, alert]);
  };

  const handleAction = async () => {
    if (availableAction) {
      setDialog(availableAction);
    }
  };

  const handleCloseDialog = () => {
    if (!isPerformingAction) {
      setDialog('');
    }
  };

  const handleSchedule = async () => {
    try {
      setIsPerformingAction(true);
      await onSchedule(session.doc_id, t);
      setDialog('');
      addAlert({
        key: 'schedule-success',
        message: t('Session successfully scheduled'),
        variant: 'success',
      });
    } catch (e) {
      console.error(e);
      reportError(
        e,
        { 'session.doc_id': session.doc_id },
        'premiumCalendar:handleSchedule',
      );
      addAlert({
        key: 'schedule-fail',
        message: e.message || t('Error scheduling session'),
        variant: 'danger',
      });
    } finally {
      setIsPerformingAction(false);
    }
  };

  const handleReschedule = async () => {
    try {
      if (session?.enrollmentInfo?.sideEnrollment?.doc_id) {
        setIsPerformingAction(true);
        await onReschedule(
          session.doc_id,
          t,
          session?.enrollmentInfo?.sideEnrollment?.doc_id,
        );
        setDialog('');
        addAlert({
          key: 'reschedule-success',
          message: t('Session successfully rescheduled'),
          variant: 'success',
        });
      }
    } catch (e) {
      console.error(e);
      reportError(
        e,
        {
          'session.doc_id': session.doc_id,
          sideEnrollment: toJS(session?.enrollmentInfo?.sideEnrollment),
        },
        'premiumCalendar:handleReschedule',
      );
      addAlert({
        key: 'reschedule-fail',
        message: t('Error rescheduling session'),
        variant: 'danger',
      });
    } finally {
      setIsPerformingAction(false);
    }
  };

  const handleCancellation = async () => {
    try {
      if (session?.enrollmentInfo?.exactEnrollment?.doc_id) {
        setIsPerformingAction(true);
        await onCancel(session?.enrollmentInfo?.exactEnrollment?.doc_id); // enrollment id
        setDialog('');
        addAlert({
          key: 'cancel-success',
          message: t('Session successfully canceled'),
          variant: 'success',
        });
      }
    } catch (e) {
      console.error(e);
      reportError(
        e,
        {
          'session.doc_id': session.doc_id,
          exactEnrollment: toJS(session?.enrollmentInfo?.exactEnrollment),
        },
        'premiumCalendar:handleCancellation',
      );
      addAlert({
        key: 'cancel-fail',
        message: t('Error canceling session'),
        variant: 'danger',
      });
    } finally {
      setIsPerformingAction(false);
    }
  };

  const renderActionBar = () => {
    if (hasSessionAlreadyBegun) {
      if (isSessionOver) {
        return (
          <Label
            variant="outline"
            icon={<TimeIcon />}
            className="live-session-calendar__in-progress-label"
          >
            {t('Session ended')}
          </Label>
        );
      }
      return (
        <Label
          variant="outline"
          icon={<TimeIcon />}
          className="live-session-calendar__in-progress-label"
        >
          {t('Session in progress')}
        </Label>
      );
    }

    if (availableAction) {
      const buttonLabel = {
        [CONFIRMATION_DIALOGS.schedule]: t('Schedule'),
        [CONFIRMATION_DIALOGS.reschedule]: t('Reschedule'),
        [CONFIRMATION_DIALOGS.cancel]: t('Cancel'),
      };

      let disableDescription = null;

      if (userStore.isFreeTierSubscriber) {
        disableDescription = t(
          'You cannot perform this action as a Free Subscription user. Upgrade to Premium Subscription!',
        );
      } else if (buttonLabel[availableAction] !== 'Cancel') {
        if (userStore.hasReachedCurrentSubscriptionPremiumVTLimit) {
          disableDescription = t(
            'You cannot perform this action because you have reached the session limit.',
          );
        } else if (
          userStore.hasReachedCurrentSubscriptionEnrollPremiumVTLimit
        ) {
          disableDescription = t(
            'You cannot perform this action because you have reached the session enroll limit.',
          );
        }
      } else if (
        isAfterCurrentSubscriptionEnd &&
        [
          CONFIRMATION_DIALOGS.schedule,
          CONFIRMATION_DIALOGS.reschedule,
        ].includes(availableAction)
      ) {
        disableDescription =
          'You cannot perform this action because your subscription will expire before the session ends.';
      }

      const isDisabled = Boolean(disableDescription);

      const button = (
        <Button
          data-analytics-id={`session-card-${availableAction}-action-btn-premium-calendar-lp-${session.premvt_session_slug}`}
          className="live-session-calendar__action-btn"
          variant="tertiary"
          isAriaDisabled={isDisabled}
          onClick={handleAction}
        >
          {buttonLabel[availableAction]}
        </Button>
      );

      if (isDisabled) {
        return <Tooltip content={disableDescription}>{button}</Tooltip>;
      }
      return button;
    }
    return null;
  };

  if (!session) {
    return null;
  }

  const { code } = extractFromSlug(session.offering_slug);

  return (
    <>
      <PremiumCalendarCard
        cardAttributes={{
          'data-analytics-id': `session-card-premium-calendar-lp-${session.premvt_session_slug}`,
        }}
        code={code}
        title={title}
        subtitle={t(`Session ${getSessionNumber(session)}`)}
        startTime={startTime.format(cardTimeFormat)}
        endTime={endTime.format(cardTimeFormat)}
        durationLabel={t('Duration')}
        duration={duration}
        instructorLabel={t('Instructor')}
        instructor={getInstructorName(session)}
        actions={renderActionBar()}
        isLoadingCollaterals={isLoadingCollaterals}
        loadingScreenreaderText={t('Loading chapters')}
        isRetired={isRetired}
        retiredMessage={t(
          'The currently scheduled version of this course has been retired. During your session, the instructor will teach the most currently available version in your subscription.',
        )}
      >
        <ul className="live-session-calendar__chapter-list">
          {chapters.map((c) => (
            <li key={c.tag}>
              {userStore.isFreeTierSubscriber &&
              progressStore.isChapterEnabled(c.tag) ? (
                <Button variant="link" isDisabled>
                  {`${c.formattedTag} - ${c.title}`}
                </Button>
              ) : (
                <Link
                  data-analytics-id={`session-card-chapter-link-premium-calendar-lp-${session.premvt_session_slug}`}
                  routeName="courses:page"
                  routeParams={{
                    course: `${session.offering_slug}`,
                    page: c.tag,
                  }}
                  href
                >
                  {`${c.formattedTag} - ${c.title}`}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </PremiumCalendarCard>
      <Modal
        data-analytics-id={`session-card-${availableAction}-modal-premium-calendar-lp-${session.premvt_session_slug}`}
        variant={ModalVariant.small}
        title={t('Class cancellation')}
        isOpen={dialog === CONFIRMATION_DIALOGS.cancel}
        onClose={handleCloseDialog}
        actions={[
          <Button
            data-analytics-id={`session-card-${availableAction}-modal-confirm-btn-premium-calendar-lp-${session.premvt_session_slug}`}
            key="confirm"
            isLoading={isPerformingAction}
            isDisabled={isPerformingAction}
            variant="danger"
            onClick={handleCancellation}
          >
            {isPerformingAction
              ? t('Canceling')
              : t('Confirm class cancellation')}
          </Button>,
          <Button
            data-analytics-id={`session-card-${availableAction}-modal-abort-btn-premium-calendar-lp-${session.premvt_session_slug}`}
            key="abort"
            isDanger
            isDisabled={isPerformingAction}
            variant="link"
            onClick={handleCloseDialog}
          >
            {t('Abort')}
          </Button>,
        ]}
      >
        <p>
          {t(
            `You are about to cancel your scheduled session for "${title}" on ${startTime.format(
              cardDateAndTimeFormat,
            )}.`,
          )}
        </p>
        <p>
          {t(
            ` Are you sure you want to continue with the cancellation? If there are no seats remaining in this class, you will not be able to reschedule.`,
          )}
        </p>
      </Modal>
      <Modal
        data-analytics-id={`session-card-${availableAction}-modal-premium-calendar-lp-${session.premvt_session_slug}`}
        variant={ModalVariant.small}
        title={t('Class enrollment')}
        isOpen={dialog === CONFIRMATION_DIALOGS.schedule}
        onClose={handleCloseDialog}
        actions={[
          <Button
            data-analytics-id={`session-card-${availableAction}-modal-confirm-btn-premium-calendar-lp-${session.premvt_session_slug}`}
            key="confirm"
            isLoading={isPerformingAction}
            isDisabled={isPerformingAction}
            variant="primary"
            onClick={handleSchedule}
          >
            {isPerformingAction ? t('Scheduling') : t('Schedule')}
          </Button>,
          <Button
            data-analytics-id={`session-card-${availableAction}-modal-cancel-btn-premium-calendar-lp-${session.premvt_session_slug}`}
            key="cancel"
            isDisabled={isPerformingAction}
            variant="link"
            onClick={handleCloseDialog}
          >
            {t('Cancel')}
          </Button>,
        ]}
      >
        <p>
          {t(
            `You are about to schedule this session for "${title}" on ${startTime.format(
              cardDateAndTimeFormat,
            )}.`,
          )}
        </p>
        <p>{t('Are you sure you want to schedule this session?')}</p>
      </Modal>
      <Modal
        data-analytics-id={`session-card-${availableAction}-modal-premium-calendar-lp-${session.premvt_session_slug}`}
        variant={ModalVariant.small}
        title={t('Reschedule class')}
        isOpen={dialog === CONFIRMATION_DIALOGS.reschedule}
        onClose={handleCloseDialog}
        actions={[
          <Button
            data-analytics-id={`session-card-${availableAction}-modal-confirm-btn-premium-calendar-lp-${session.premvt_session_slug}`}
            key="confirm"
            isLoading={isPerformingAction}
            isDisabled={isPerformingAction}
            variant="primary"
            onClick={handleReschedule}
          >
            {isPerformingAction ? t('Rescheduling') : t('Reschedule')}
          </Button>,
          <Button
            data-analytics-id={`session-card-${availableAction}-modal-cancel-btn-premium-calendar-lp-${session.premvt_session_slug}`}
            key="cancel"
            isDisabled={isPerformingAction}
            variant="link"
            onClick={handleCloseDialog}
          >
            {t('Cancel')}
          </Button>,
        ]}
      >
        <p>
          {t(
            `You already have this session "${title}" scheduled for a different time.`,
          )}
        </p>
        <p>
          {t(
            `Are you sure you want to cancel your currently scheduled session, and reschedule it to ${startTime.format(
              cardDateAndTimeFormat,
            )}?`,
          )}
        </p>
      </Modal>
      <AlertGroup isToast isLiveRegion>
        {alerts.map((alert) => (
          <Alert
            variant={AlertVariant[alert.variant]}
            title={alert.message}
            timeout={false}
            onTimeout={() => removeAlert(alert.key)}
            actionClose={
              <AlertActionCloseButton
                title={alert.title}
                onClose={() => removeAlert(alert.key)}
              />
            }
            key={alert.key}
          />
        ))}
      </AlertGroup>
    </>
  );
}

Card.propTypes = {
  session: PropTypes.object,
  isLoadingCollaterals: PropTypes.bool.isRequired,
  onSchedule: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onReschedule: PropTypes.func.isRequired,
  currentSubscriptionEndTime: PropTypes.string.isRequired,
};

Card.defaultProps = {
  session: {},
};

export default withErrorBoundary(Card, ComponentError, handleError);
